<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar :src="items.logo" size="104px" rounded />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ items.name }}
                </h4>
                <span class="card-text"> {{ items.basicId }} </span>
              </div>
              <div class="d-flex flex-wrap">
                <!-- <b-button variant="primary"> Edit </b-button> -->
                <b-button
                  variant="outline-danger"
                  class="ml-1"
                  @click="delete_data(items.id)"
                >
                  Delete
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="CpuIcon" class="mr-75" />
                <span class="font-weight-bold"> {{ items.name }}</span>
              </th>
              <td class="pb-50"></td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="AtSignIcon" class="mr-75" />
                <span class="font-weight-bold">{{ items.basicId }}</span>
              </th>
              <td class="pb-50 text-capitalize"></td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">{{ items.date_create }} </span>
              </th>
              <td class="pb-50 text-capitalize"></td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-row class="match-height">
      <b-col lg="4" md="6">
        <b-card
          :img-src="require('@/assets/images/banner/parallax-4.jpg')"
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile"
        >
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <b-avatar
                size="114"
                variant="light"
                :src="items.Get_bot_info.pictureUrl"
              />
            </div>
          </div>
          <h3>{{ items.Get_bot_info.displayName }}</h3>

          <b-badge class="profile-badge" variant="light-primary">
            {{ items.Get_bot_info.basicId }}
          </b-badge>
          <hr class="mb-2" />

          <!-- follower projects rank -->
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h6 class="text-muted font-weight-bolder">followers</h6>
              <h3 class="mb-0">{{ items.fetchURL.followers }}</h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder">targetedReaches</h6>
              <h3 class="mb-0">{{ items.fetchURL.targetedReaches }}</h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder">blocks</h6>
              <h3 class="mb-0">{{ items.fetchURL.blocks }}</h3>
            </div>
          </div>
        </b-card>
      </b-col>
    
      <b-col lg="4" md="6">
        <b-card class="card-transaction" no-body>
          <b-card-header
            ><br />
            <b-card-title>&nbsp;&nbsp;Transactions</b-card-title>
          </b-card-header>

          <b-card-body>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="42" variant="light-primary">
                    <feather-icon size="18" icon="PocketIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">การส่งข้อความ/ต่อเเดือน</h6>
                  <small>{{ items.Get_bot_info.displayName }}</small>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder text-success">
                {{ items.Llimited.value }}
              </div>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="42" variant="light-primary">
                    <feather-icon size="18" icon="PocketIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">Replymessages</h6>
                  <small>{{ items.replymessages.status }}</small>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder text-success">
                {{ items.replymessages.success }}
              </div>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="42" variant="light-primary">
                    <feather-icon size="18" icon="PocketIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">Pushmessages</h6>
                  <small>{{ items.pushmessages.status }}</small>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder text-success">
                {{ items.pushmessages.success }}
              </div>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="42" variant="light-primary">
                    <feather-icon size="18" icon="PocketIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="transaction-title">Multicast Messages</h6>
                  <small>{{ items.multicastmessages.status }}</small>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder text-success">
                {{ items.multicastmessages.success }}
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatarGroup,
  BMediaBody,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import api from "@/api";
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatarGroup,
    BMediaBody,
  },

  data() {
    return {
      items: "",
    };
  },
  mounted() {
    this.loaddata();
  },
  methods: {
    loaddata() {
      
      const params = {
       store_id: localStorage.getItem("store_id"),
      };
      var access_token = localStorage.getItem("accessToken");
      api
        .post("check_connect_oa", params, {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.items = response.data.data[0];
          this.totalRows = response.data.total;
          if (response.data.total === 0) {
            this.$router.push("/lineoa/add");
          }
          console.log("this.items :>> ", this.items);
        })
        .catch((error) => {
          console.log(error);
          this.$router.push("/lineoa/add");
        });
    },
    delete_data(id) {
      //   alert(id);
      this.$swal({
        title: "คุณต้องการลบ LINE OA: " + this.items.name,
        text: "คุณจะไม่สามารถย้อนกลับได้!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const params = {
            id: id,
          };
          
          api
            .post("del_lineoa", params, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              this.loaddata();
              this.$swal({
                icon: "success",
                title: "ลบ! Device mac: " + mac,
                text: "ข้อมูลของคุณถูกลบไปแล้ว",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {});
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>